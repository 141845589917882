export const Variants = Object.freeze({
  general: {
    headerClasses: 'my-4 md:m-4 mx-2',
    hasTitleDivider: true,
    contentClasses: 'my-4 md:m-4 ',
  },
  pdp: {
    headerClasses: 'my-0',
    hasTitleDivider: false,
    contentClasses: 'my-0 mb-4',
  },
})
