
import { Variants } from './enums'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'
import { UiTitle, Divider, Card } from '~/components/atoms'

export default {
  components: {
    UiTitle,
    Card,
    Divider,
    RichTextRenderer,
  },
  props: {
    type: { type: String, default: 'general' },
    noTruncate: { type: Boolean, default: false },
    info: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showAll: false,
      isShowAllVisible: false,
      showMoreTranslateKey: 'general.show_more',
      heightClass: 'min-h-auto',
      variant: Variants[this.type],
    }
  },
  computed: {
    truncateClasses() {
      return this.showAll || this.noTruncate
        ? ''
        : 'truncate-5-lines md:truncate-3-lines'
    },
  },
  mounted() {
    const { scrollHeight, clientHeight } = this.$refs.infoBox

    if (scrollHeight > 90) {
      this.isShowAllVisible = true
    }

    if (!clientHeight) {
      this.heightClass = 'min-h-18'
    }
  },
  methods: {
    onToggleContent() {
      this.showAll = !this.showAll
      if (this.showAll) {
        this.showMoreTranslateKey = 'general.show_less'
      } else {
        this.showMoreTranslateKey = 'general.show_more'
      }
    },
  },
}
